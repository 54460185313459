import React from "react"

export default function Wrapper({child, classNames, styles, _Ref}) {
  return (
    <div 
      ref={_Ref}
      className={`w-full ${classNames ? classNames : ''}`} 
      style={styles ? styles : {listStyle: 'none'}}>
      <div className={`container lg mx-auto`}>
        {child}
      </div>
    </div>
  )
}